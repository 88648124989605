<template>
	<v-container class="px-1">
		<v-row
			tag="section"
			class="mx-auto px-0 px-md-2 my-md-5 py-lg-5"
			justify-lg="center"
		>
			<v-col
				cols="12"
				md="6"
				xl="4"
				class="hero text-center"
			>
				<h1 class="primary--text text-lg-h4 my-1 mb-md-5">¡Bienvenido a la Peña!</h1>
				<p class="text-body-1 font-italic font-weight-regular text-lg-h6 my-0">
					Bienvenido a la Peña Madridista de la Ciudad de México, un lugar en el que podrás compartir tu gran
					pasión por el Real Madrid en compañía de más madridistas.
				</p>
				<v-btn
					to="registro"
					color="accent lighten-2"
					class="grey--text text--darken-4 my-3 mt-md-5"
				>¡Hazte Peñista!</v-btn>
			</v-col>
			<v-col
				cols="12"
				md="6"
				xl="4"
				class="px-0"
			>
				<v-carousel
					continuous
					:height="carouselHeight"
					hide-delimiters
					show-arrows
					cycle
					interval="5000"
					:class="{ carousel: $vuetify.breakpoint.mdAndUp }"
				>
					<v-carousel-item
						v-for="i in 6"
						:key="i"
						:src="getImgUrl(i)"
					></v-carousel-item>
				</v-carousel>
			</v-col>
		</v-row>
		<template v-if="$vuetify.breakpoint.smAndDown">
			<ver-partido
				v-if="ProximoPartido"
				:partido="ProximoPartido"
				class="mt-4"
			>
				<template v-slot:titulo>
					Próximo Partido
				</template>
			</ver-partido>
			<v-tabs
				grow
				color="secondary"
				v-model="tab"
			>
				<!-- <v-tab class="px-2">Análisis</v-tab> -->
				<v-tab class="px-2">Noticias</v-tab>
				<v-tab class="px-2">Posiciones</v-tab>
			</v-tabs>
			<v-tabs-items
				v-model="tab"
				touchless
			>
				<!-- <v-tab-item class="pt-5">
					<template v-if="analisisPost && analisisPrev">
						<template v-if="analisisPost.partidoId == analisisPrev.partidoId">
							<analisis-post :analisis="analisisPost"></analisis-post>
							<analisis-prev :analisis="analisisPrev"></analisis-prev>
						</template>
						<template v-else>
							<analisis-prev :analisis="analisisPrev"></analisis-prev>
							<analisis-post :analisis="analisisPost"></analisis-post>
						</template>
					</template>
				</v-tab-item> -->
				<v-tab-item class="pt-5">
					<ver-noticia
						v-for="n in noticiasPena"
						:key="n.id"
						:noticia="n"
					></ver-noticia>
				</v-tab-item>
				<v-tab-item class="pt-5">
					<tabla-posiciones :posiciones="posiciones"></tabla-posiciones>
				</v-tab-item>
			</v-tabs-items>
		</template>
		<template v-else>
			<v-row justify="space-around">
				<!-- <v-col md="4">
					<template v-if="analisisPost && analisisPrev">
						<template v-if="analisisPost.partidoId == analisisPrev.partidoId">
							<analisis-post :analisis="analisisPost"></analisis-post>
							<analisis-prev :analisis="analisisPrev"></analisis-prev>
						</template>
						<template v-else>
							<analisis-prev :analisis="analisisPrev"></analisis-prev>
							<analisis-post :analisis="analisisPost"></analisis-post>
						</template>
					</template>
				</v-col> -->
				<v-col md="4">
					<ver-noticia
						v-for="(n, i) in noticiasPena"
						:key="i"
						:noticia="n"
					></ver-noticia>
				</v-col>
				<v-col
					md="4"
					xl="3"
				>
					<!-- <ver-partido
						v-if="ProximoPartido"
						:partido="ProximoPartido"
					>
						<template v-slot:titulo>
							Próximo Partido
						</template>
					</ver-partido> -->
					<tabla-posiciones :posiciones="posiciones"></tabla-posiciones>
				</v-col>
			</v-row>
		</template>
	</v-container>
</template>

<script>
import VerPartido from '@/components/VerPartido.vue';
import AnalisisPrev from '@/components/AnalisisPrev.vue';
import AnalisisPost from '@/components/AnalisisPost.vue';
import TablaPosiciones from '@/components/TablaPosiciones.vue';
import VerNoticia from '@/components/VerNoticia.vue';

export default {
	name: 'VistaInicio',

	components: {
		VerPartido,
		AnalisisPrev,
		AnalisisPost,
		TablaPosiciones,
		VerNoticia,
	},

	metaInfo() {
		return {
			title: 'Peña Madridista de la Ciudad de México'
		};
	},

	data: () => ({
		tab: null,
	}),

	computed: {
		carouselHeight() {
			if (this.$vuetify.breakpoint.mdAndUp) {
				return '250';
			} else {
				return '150';
			}
		},
		calendario() {
			return this.$store.getters.getCalendario;
		},
		ProximoPartido() {
			let hoy = new Date();
			return this.calendario.find(p => (new Date(p.fecha.toDate()) >= hoy));
		},
		posiciones() {
			return this.$store.getters.getPosiciones;
		},
		analisisPrev() {
			return this.$store.getters.getAnalisisPrev[0];
		},
		analisisPost() {
			return this.$store.getters.getAnalisisPost[0];
		},
		noticiasPena() {
			return this.$store.getters.getNoticias;
		},
	},

	methods: {
		getImgUrl(pic) {
			return require('../assets/img/hero0' + pic + '.jpg');
		}
	},

	created() {
		if (this.posiciones.length == 0) {
			this.$store.dispatch('setPosiciones');
		}
	}
};
</script>

<style lang="scss" scoped>
	.isLg {
		width: 80%;
	}
	.isXl {
		width: 55%;
	}
	.hero {
		background-image: url('~@/assets/img/background-logo.jpg');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}
	.carousel {
		border-radius: 6px;
	}
</style>
