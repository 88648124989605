import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import VistaInicio from '../views/VistaInicio.vue';

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
	{
		path: '/',
		name: 'inicio',
		component: VistaInicio
	},
	{
		path: '/registro',
		name: 'VistaRegistro',
		component: () => import(/* webpackChunkName: "VistaRegistro" */ '../views/VistaRegistro.vue'),
		meta: {
			title: '¡Hazte Peñista! - Peña Madridista de la Ciudad de México',
		}
	},
	{
		path: '/lapena',
		name: 'VistaLaPena',
		component: () => import(/* webpackChunkName: "VistaLaPena" */ '../views/VistaLaPena.vue'),
		meta: {
			title: 'La Peña - Peña Madridista de la Ciudad de México',
		}
	},
	{
		path: '/eventos',
		name: 'VistaEventos',
		component: () => import(/* webpackChunkName: "VistaEventos" */ '../views/VistaEventos.vue'),
		meta: {
			title: 'Eventos - Peña Madridista de la Ciudad de México',
		}
	},
	{
		path: '/galeria',
		name: 'VistaGaleria',
		component: () => import(/* webpackChunkName: "VistaGaleria" */ '../views/VistaGaleria.vue'),
		meta: {
			title: 'Galería - Peña Madridista de la Ciudad de México',
		}
	},
	{
		path: '/faq',
		name: 'VistaFaq',
		component: () => import(/* webpackChunkName: "VistaFaq" */ '../views/VistaFaq.vue'),
		meta: {
			title: 'Preguntas Frecuentes - Peña Madridista de la Ciudad de México',
		}
	},
	{
		path: '/contacto',
		name: 'VistaContacto',
		component: () => import(/* webpackChunkName: "VistaContacto" */ '../views/VistaContacto.vue'),
		meta: {
			title: 'Contacto - Peña Madridista de la Ciudad de México',
		}
	},
	{
		path: '/aviso-de-privacidad',
		name: 'AvisoPrivacidad',
		component: () => import(/* webpackChunkName: "AvisoPrivacidad" */ '../views/AvisoPrivacidad.vue'),
		meta: {
			title: 'Aviso de Privacidad - Peña Madridista de la Ciudad de México',
		}
	},
	{
		path: '/validar',
		name: 'ValidarCarnet',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "ValidarCarnet" */ '../views/ValidarCarnet.vue'),
		props: route => ({ selloDigitalU: route.query.SELLO, selloDigitalL: route.query.sello })
	},
	{
		path: '/bulk',
		name: 'BulkView',
		component: () => import(/* webpackChunkName: "Bulk" */ '../views/BulkView.vue'),
		meta: {
			title: 'Bulk - Peña Madridista de la Ciudad de México',
		}
	},
	{
		path: '/404',
		name: '404',
		component: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFound.vue'),
		meta: {
			title: 'Peña Madridista de la Ciudad de México',
		}
	},
	{
		path: '*',
		redirect: '/404'
	}
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router;
